@import './colours.less';

body {
  background-color: @liquid-white;
  color: @primary-dark-grey;
}

body, p {
  font-family: 'Archivo', sans-serif;
  font-weight: 200;
}

h1, h2, h3, h4, h5, h6 {
  color: @primary-dark-grey;
  font-family: "Noto Serif", serif;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  width: 100%;
  background-color: @primary-dark-grey;
  h1, p {
    color: @tertiary-white;
    margin-bottom: 30px;
  }
}


.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: @primary-gold;
}

.hero-container {
  background-image: url('~../public/images/LG-hero-image.png');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 40vw;

  h1 {
    font-size: 48px;
  }

  p {
    font-size: 24px;
  }

  .btn {
    margin-top: 20px;
    background-color: @primary-gold;
    border: none;
    padding: 10px 20px;
    color: @primary-dark-grey;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken(@primary-gold, 10%);
    }
  }
}

.carousel-video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.carousel-embed-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100% !important;
}

.carousel-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Courses Styles
.courses-container {
  padding: 30px;

  .course-card {
    margin-bottom: 30px;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border-radius: 15px;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    }

    .course-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .card-body {
      background-color: @tertiary-soft-gold;
      color: @primary-dark-brown;

      h5 {
        color: @primary-dark-grey;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: @primary-dark-grey;
      }

      .btn {
        background-color: @primary-gold;
        border: none;
        padding: 10px 20px;
        color: @tertiary-white;
        transition: all 0.3s ease;

        &:hover {
          background-color: darken(@primary-gold, 10%);
        }
      }
    }
  }
}

button {
  background-color: @secondary-blue;
  color: @primary-dark-grey;

  &:hover {
    background-color: @primary-gold;
    color: @primary-dark-brown;
  }
}

.navbar {
  cursor: pointer;
  padding-left: 50px;
  padding-right: 50px;

  .navbar-brand {
    color: @primary-gold;

    &:hover {
      color: @tertiary-soft-gold;
    }
  }

  .nav-link {
    color: @liquid-white;
    margin-right: 50px;

    &:hover {
      color: @liquid-grey;
    }
  }
}

.navbar-toggler {
  // display: none; // Hide the toggler until the dropdown menu is working
}

.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: @education-blue;
  border-bottom: 3px solid @bright-gold; /* Adding a gold border at the bottom */
}

.navbar-brand {
  color: @primary-gold; /* Changing brand color to gold */
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  .nav-link {
    &.active, 
    &.show { 
      color: @tertiary-white; 
    }
  }
}

.nav-link {
  color: @tertiary-soft-gold; /* Changing link color to a softer gold */
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: color 0.2s, transform 0.2s;
  display: flex;
  align-items: center;
  font-weight: bold; /* Making the text bold */
}

.nav-link svg {
  margin-right: 0.5rem;
  transition: color 0.2s; /* Adding transition to icon color */
}

.nav-link:hover,
.nav-link:focus {
  color: @primary-gold; /* Changing text and icon color to gold on hover and focus */
  transform: scale(1.05);
}

.nav-link:active {
  transform: scale(0.95);
}

.carousel-caption {
  background-color: fade(@primary-dark-grey, 70%);
  padding: 10px;
  border-radius: 5px;
}

.carousel-caption h3 {
  font-size: 24px;
  font-weight: bold;
  color: @primary-gold;
  margin-bottom: 10px;
}

.carousel-caption p {
  font-size: 18px;
  color: @tertiary-white;
  margin-bottom: 0;
}

.carousel-container {
  width: 100%; // Full width on mobile devices
  max-width: 1140px; // Maximum width of 1140px on desktops
  margin: 0 auto; // Center the container
  position: relative;
  padding: 2rem; // Reduce padding for very small screens
  background: @bright-gold;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 376px) {
    max-width: 360px; // Maximum width of 360px on small mobile devices
    padding: 2.5rem; // Increase padding for slightly larger screens
  }

  @media screen and (min-width: 576px) {
    max-width: 540px; // Maximum width of 540px on large mobile devices
    padding: 2.5rem; // Increase padding for larger screens
  }

  @media screen and (min-width: 768px) {
    max-width: 720px; // Maximum width of 720px on tablets
  }

  @media screen and (min-width: 992px) {
    max-width: 960px; // Maximum width of 960px on small laptops
    width: 66.67%; // 2/3rd width on larger screens
  }
}

.slick-dots {
  bottom: -30px;

  li {
    margin: 0 3px;

    button:before {
      font-size: 12px;
      color: @liquid-black;
      opacity: 0.7;
    }
    
  }
  .slick-active button:before {
    color: green;
    opacity: 0.1;
  }
}

.slick-prev {
  left: -35px
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: @liquid-black;
}

.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}


.social-links-card .card-body {
  display: flex;
  justify-content: center;
}

.social-links-header {
  font-weight: bold;
}

.social-icon {
  margin-right: 5px;
}

.social-link-tile {
  display: inline-block;
  padding: 15px;
  margin: 10px;
  background-color: @liquid-grey;
  border: 1px solid @liquid-black;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  a {
    text-decoration: none;
    color: @liquid-black;
    display: flex;
    align-items: center;
  }
}

.social-link-tile:hover {
  background-color: @liquid-white;
  border-color: @secondary-blue;
}

.social-link-tile:active {
  background-color: @bright-gold;
  border-color: @primary-dark-brown;
}

.social-link-tile:focus {
  background-color: @primary-gold;
  border-color: @primary-dark-brown;
  outline: none;
}

// sections

.section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.about-us-section,
.courses-section,
.contact-us-section {
  background-color: @liquid-grey;  
  h1, h2, p {
    color: @liquid-black;
    margin-bottom: 30px;
  }
  a {
    color: @tertiary-dark-white;
  }
}

.social-links-card {
  background-color: @education-blue;
  border-radius: 0;
  border-bottom: none;
  .social-links-header {
    color: @tertiary-white; 
  }
  h1, h2, p, .form-label {
    margin-bottom: 30px;
  }
  .asterisk {
    color: red;
  }
}

.consultancy-section,
.blog-highlights-section {
  background-color: @education-blue;
  h1, h2, p {
    color: @liquid-white;
    margin-bottom: 30px;
  }
  a {
    color: @tertiary-dark-white;
  }
}

.videos-section,
.newsletter-signup-section {
  background-color: @liquid-black;  
  h1, h2, p {
    color: @liquid-white;
    margin-bottom: 30px;
  }
  a {
    color: @tertiary-dark-white;
  }
}

.videos-section,
.blog-highlights-section {
  text-align: center; /* Center-align the text within the container */
}

.youtube-link {
  font-weight: bold;
}

.consultancy-section {
  .content-wrapper {
    margin-bottom: 50px;
  }
  a {
    color: @primary-gold;
  }
}

.contact-us-section {
  a {
    color: @primary-light-gold;
  }
}

.newsletter-signup-section {
  .form-line {
    display: flex;
    align-items: center; /* Ensures vertical centering */
    
    .form-label {
      flex: 1;
      // margin-right: -10px; /* Added spacing between label and input */
      margin-bottom: 0;
    }
    
    .form-control {
      flex: 2;
    }
  }
}


.submit-button {
  color: @liquid-black;
  background-color: @bright-gold;
  border: 2px solid @liquid-white;
  padding: 0.5em 2em;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 2em;
  margin-bottom: 2em;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: darken(@bright-gold, 10%);
  }
}

@media screen and (min-width: 900px) {
  .newsletter-signup-section {
    padding-right: 150px;
    padding-left: 150px;
  }  
}

.liquid-gold-heading {
  color: @primary-gold;
}

/* Styles for the blog cards */
.blog-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  min-height: 260px;
  margin: 20px auto;
  background-color: @tertiary-white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    .card-title {
      text-decoration: underline;
    }
  }

  .card-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
  }
  
  .card-content {
    font-size: 1em;
    color: #666;
  }
  
  .card-date {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0.8em;
    color: #aaa;
  }
  
  .card-author {
    margin-top: auto;
    font-size: 0.9em;
    color: #999;
  }
}

//blog pages
.blog-detail {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  color: @tertiary-dark-white;

  .author {
    font-style: italic;
    margin-bottom: 8px;
  }

  .source {
    color: #666;
    margin-bottom: 16px;
  }

  .detail-title {
    display: flex;
    justify-content: center;
    color: @tertiary-dark-white;
  }

  .detail-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
    span {
      margin-right: 10px;
    }
  }

  .detail-content {   
    color: @tertiary-dark-white;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-bottom: 1rem;
  }

  .back-button {
    display: inline-block;
    text-decoration: none;
  }
  
  .back-button {
  background-color: @secondary-dark-blue;
    color: @tertiary-white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}

/* Styles for the blog page */

.blog-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: @liquid-white;
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    color: black;
  }
  a {
    text-decoration: none;
  }

  .blog-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: @liquid-grey;

    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .author {
      font-style: italic;
    }

    .source {
      color: #666;
    }
  }
}

.blog-highlights-section a {
  text-decoration: none;
}

.blog-card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  width: 350px;
  height: 350px;

  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .author {
    font-style: italic;
  }

  .source {
    color: #666;
  }
}

.testimonial {
  margin-top: 50px;
}

.testimonial-text {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: @liquid-black;
}

.testimonial-author {
  font-size: 16px;
  font-weight: bold;
  color: @liquid-black;
}

// Consulting Section

/* Making the container a flex container */
.consultancy-section .container {
  display: flex;
  flex-direction: column;  /* Stacking children vertically */
  align-items: center; /* Horizontally centering the content */
}

/* Styling for the first section */
.consultancy-section .intro-section {
  width: 100%; /* Taking up the full width */
  margin-bottom: 20px; /* Some margin for spacing between rows */
}

/* New container for services and screenshot */
.consultancy-section .content-wrapper {
  display: flex;
  width: 100%; /* Taking up the full width */
  justify-content: space-between; /* Space between the two sections */
}

/* Styling for the second section */
.consultancy-section .services-section {
  flex: 1; /* This will make it adjust based on the remaining space */
  margin-right: 2%; /* Some margin for spacing between the two sections */
  color: white;

  h3 {
    color: white;
  }
}


.screenshot-section {
  flex: 1; /* This will make it adjust based on the remaining space */
  display: flex; /* Using flex to help center the image vertically */
  align-items: center; /* Centers the image vertically */
  justify-content: center; /* Centers the image horizontally */

  img {
    max-width: 90%; /* This will make the image scale if it's larger than the div, and also provides some space for the border */
    display: block; /* Removes any extra space below the image */
    border: 5px solid #B8860B; /* A gold color for the border */
    box-shadow: 0px 0px 10px rgba(184, 134, 11, 0.5); /* Optional: A subtle gold shadow to give the image a bit of depth */
    border-radius: 8px; /* Optional: Rounds the corners of the image for a more polished look */
  }
}

/* Styling for the golden bullet points */
.services-section ul {
  list-style-type: none; /* Removes default bullets */
  padding-left: 20px;   /* Adds a little space for custom bullet */
}

.services-section ul li::before {
  content: "\2022";   /* Unicode character for a bullet point */
  color: #FFD700;     /* Gold color */
  font-size: 2.2em;   /* Slightly larger bullet */
  font-weight: bold;  /* Bold bullet */
  display: inline-block;
  margin-right: 10px; /* Space between the bullet and text */
  text-shadow: 
      1px 1px 2px rgba(255, 223, 0, 0.7), 
      -1px -1px 2px rgba(255, 223, 0, 0.7), 
      1px -1px 2px rgba(255, 223, 0, 0.7), 
      -1px 1px 2px rgba(255, 223, 0, 0.7); /* Glow effect */
}

/* Optional: If you'd like a gradient for a richer golden effect */
.services-section ul li::before {
  background: -webkit-linear-gradient(top, #FFD700, #FFCC00);
  background: -moz-linear-gradient(top, #FFD700, #FFCC00);
  background: -o-linear-gradient(top, #FFD700, #FFCC00);
  background: linear-gradient(to bottom, #FFD700, #FFCC00);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
@primary-color: #1DA57A;